import grainImg from '../assets/images/grain.jpg';
import grain_light from '../assets/images/grain-light.jpg';
import grain_dark from '../assets/images/grain-dark.png';
const Grain = (props) => {
    const {colorScheme} = props;
    return <div className={'Grain'}>
        <img className={'Grain-Img'} src={(colorScheme === "dark" ? grain_dark : grain_light)} alt={'Film Grain'}/>
    </div>;
};

export default Grain;