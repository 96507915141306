const Project = (props) => {
    const {project} = props;

    return <main className={'Main'}>
        <div className={'Project'}>
            <header className={'Project-Header'}>
                <div className={'ProjectPreview-Container'}>
                    <div className={'ProjectPreview-ImgContainer'}>
                        <img src={project.img} alt={'Preview'} className={'ProjectPreview-Img'}/>
                    </div>
                    <div className={'ProjectPreview-TextContainer'}>
                        <h1 className={'ProjectPreview-Title'}>{project.title}</h1>
                        <p className={'ProjectPreview-Description'}>{project.subtitle}</p>
                    </div>
                </div>
            </header>
            <div className={'Grid'}>
                <div className={'Grid-Item Grid-Item--span-2'}>
                    <img className={'Img'} src={''} style={{backgroundColor: '#4F4B4A', height: '500px'}}/>
                </div>
                <div className={'Grid-Item Grid-Item--span-1'}>
                    <p className={'Caption'}>Lorem ipsum</p>
                    <p className={'Paragraph'}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
            </div>
        </div>
    </main>;
};

export default Project;