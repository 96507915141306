import {useEffect, useState} from "react";

/**
 * returns dark or light, depending on the preferred color scheme
 * @return {{colorScheme: 'unknown'|'dark'|'light'}}
 */
const useColorScheme = () => {
    const [colorScheme, setColorScheme] = useState('unknown');
    useEffect(() => {
        const updateColorScheme = (e) => {
            const scheme = e?.matches ? 'dark' : 'light';
            setColorScheme(scheme);
        };
        const prefersDarkScheme = '(prefers-color-scheme: dark)';
        updateColorScheme(window.matchMedia(prefersDarkScheme));
        window.matchMedia(prefersDarkScheme).addEventListener('change', updateColorScheme);
        return () =>  window.matchMedia(prefersDarkScheme).removeEventListener('change', updateColorScheme);
    }, []);
    return {colorScheme};
};

export default useColorScheme;