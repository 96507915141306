import {motion, AnimatePresence} from "framer-motion";
import {AnimateHeading, AnimateParagraph, RevealOnScroll} from "./Animations";
import ScrollIndicator from "./ScrollIndicator";
import useResetScroll from "../helpers/useResetScroll";

const Experiments = () => {
    useResetScroll();
    return <main className={'Main --margin-lg-lr'}>
        <header className={'Main-Header'}>
            <h1 className={'Heading Heading-1'}>
                <AnimateHeading text={'Experiments'} reverse/>
            </h1>
            <div className={'Grid'} style={{marginBottom: 0}}>
                <p className={'Grid-Item Grid-Item--span-2 Paragraph'}>
                    <AnimateParagraph
                        text={'Whether it\'s an animation, a game or a website - I\'m always tinkering on something. Because not all of these ideas result in a big project, some of them are shown here.'}
                    />
                </p>
            </div>
            {/*<ScrollIndicator position={'static'}/>*/}
        </header>
        <div className={'Grid --margin-lg-tb'}>
            <div className={'Grid-Item Grid-Item--span-2'}>
                <RevealOnScroll delay>
                    <img
                        className={'Img'}
                        src={'/'}/>
                </RevealOnScroll>
            </div>
            <div className={'Grid-Item Grid-Item--span-1'}>
                <RevealOnScroll>
                    <p className={'Caption'}>Raycasting 2D shadows</p>
                    <p className={'Paragraph'}>
                        When casting shadows in a top-down environment, most games solve the problem by raycasting in all directions. However, raycasting at the corners of each rectangle can drastically improve the efficiency.
                    </p>
                </RevealOnScroll>
            </div>
        </div>
        <div className={'Grid --margin-lg-tb'}>
            <div className={'Grid-Item Grid-Item--span-1'}>
                <RevealOnScroll>
                    <p className={'Caption'}>Custom Cursor</p>
                    <p className={'Paragraph'}>
                        A custom mouse cursor is not a purely aesthetic choice, but can also communicate different interactions and lead to a more fun and engaging user experience.
                    </p>
                </RevealOnScroll>
            </div>
            <div className={'Grid-Item Grid-Item--span-1'}>
                <RevealOnScroll delay>
                    <img
                        className={'Img'}
                        src={'/'}/>
                </RevealOnScroll>
            </div>
            <div className={'Grid-Item Grid-Item--span-1'}>
                <RevealOnScroll delay>
                    <img
                        className={'Img'}
                        src={'/'}/>
                </RevealOnScroll>
            </div>
        </div>
    </main>;
};

export default Experiments;