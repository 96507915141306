import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {motion} from "framer-motion";

// todo onscroll, onleave fade out
const CustomCursor = (props) => {
    const wrapperRef = useRef();
    const [position, setPosition] = useState({x: 0, y: 0});
    const [isOverButton, setIsOverButton] = useState(false);

    useLayoutEffect(() => {
        const onMouseMove = (e) => {
            setPosition({x: e.clientX, y: e.clientY});
            const target = e.target;

            if (!!target.closest('.--hide-cursor')) {
                wrapperRef.current.classList.add('CursorWrapper--hide');
            }
            else {
                wrapperRef.current.classList.remove('CursorWrapper--hide');
            }

            const button = target.closest('button');
            const link = target.closest('a');
            if (button || link) {
                setIsOverButton(true);
            } else {
                setIsOverButton(false);
            }
        };
        window.addEventListener('mousemove', onMouseMove);
        return () => window.removeEventListener('mousemove', onMouseMove);
    }, []);

    return <div className={'CursorWrapper'} ref={wrapperRef}>
        <div
            className={'Cursor'}
            style={{transform: `translate(${position.x}px, ${position.y}px)`}}>
            <div className={'Cursor-Dot'}/>
        </div>
        <motion.div
            className={'Cursor'}
            animate={{x: position.x, y: position.y}}
            transition={{type: 'spring', stiffness: 120, damping: 10, mass: .1}}>
            <div className={'Cursor-Outline' + (isOverButton ? ' Cursor-Outline--interact' : '')}/>
        </motion.div>
    </div>;
}

export default CustomCursor;