import {motion, AnimatePresence} from "framer-motion";
import {Link} from "react-router-dom";
import {Animate, AnimateHeading, MoveTopLeft} from "./Animations";
import useResetScroll from "../helpers/useResetScroll";

const About = (props) => {
    useResetScroll();
    return <main className={'Main --margin-lg-lr'}>
        <header className={'Main-Header'}>
            <h1 className={'Heading Heading-1'}>
                <Animate text={'Florian'} animation={'moveTopLeft'}/><br/>
                <span style={{marginLeft: '1.1em'}}><Animate text={'Elmhorst'} animation={'moveTopRight'}/></span>
            </h1>
        </header>
        <div className={'Grid'}>
            <header className={'Grid-Item Grid-Item--expand'}>
                <h2 className={'Heading'}>Who I am</h2>
            </header>
            <div className={'Grid-Item Grid-Item--span-2 Grid-Item--expand'}>
                <p className={'Paragraph'}>
                    Hey! I'm Florian Elmhorst, an interaction designer and frontend developer with a passion for
                    creating engaging digital experiences. With an eye for visual design and a good technical
                    understanding, I combine the best of two worlds and bring a unique perspective to every project I
                    undertake.
                </p>
                <p className={'Paragraph'}>
                    If you would like to get in touch, contact me here: <a className={'Link'}
                                                                           href={'mailto:florian.elmhorst@outlook.de'}>
                    florian.elmhorst@outlook.de
                </a>
                </p>
            </div>
        </div>
        <div className={'Grid'}>
            <header className={'Grid-Item Grid-Item--expand'}>
                <h2 className={'Heading'}>Experience</h2>
            </header>
            <div className={'Grid-Item Grid-Item--span-2 Grid-Item--expand'}>

                <div className={'Experience'}>
                    <p className={'Caption --emphasize'}>now</p>
                    <p className={'Paragraph Experience-Paragraph'}>
                        working on <a
                        className={'Link'}
                        href={'https://visually-app.com'}
                        target={'_blank'} rel={'noreferrer'}
                    >Visually</a>
                    </p>
                </div>

                <div className={'Experience'}>
                    <p className={'Caption'}>2019 - 2023</p>
                    <p className={'Paragraph Experience-Paragraph'}>
                        B.A. Media & Interaction Design<br/>
                        at <a
                        className={'Link'}
                        href={'https://www.hs-osnabrueck.de/en/study/study-offerings/bachelor/media-interaction-design-ba/'}
                        target={'_blank'} rel={'noreferrer'}
                    >University of Applied Sciences</a> in Osnabrück
                    </p>
                </div>

                <div className={'Experience'}>
                    <p className={'Caption'}>2021 - 2023</p>
                    <p className={'Paragraph Experience-Paragraph'}>
                        Frontend Developer<br/>
                        at <a
                        className={'Link'}
                        href={'https://iscope.de/'}
                        target={'_blank'} rel={'noreferrer'}
                    >ISCOPE</a> in Osnabrück
                    </p>
                </div>

                <div className={'Experience'}>
                    <p className={'Caption'}>2017</p>
                    <p className={'Paragraph Experience-Paragraph'}>
                        Art & Design Diploma<br/>
                        at <a
                        className={'Link'}
                        href={'https://www.boston.ac.uk/courses/find-a-course/area/art-and-design/'}
                        target={'_blank'} rel={'noreferrer'}
                    >Boston College</a>
                    </p>
                </div>
            </div>
        </div>
    </main>;
};

export default About;