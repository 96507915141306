import test_img from '../assets/images/img.png';
import stripes_img from '../assets/images/coming_soon/stripes.png';

import {useEffect, useRef, useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {Link} from "react-router-dom";
import ScrollIndicator from "./ScrollIndicator";

const projects = [{
    title: 'Visually',
    path: '/visually',
    subtitle: 'a web application that helps users create impactful data visualizations tailored to their specific data',
    img: test_img
}, {
    title: 'stickyTokens',
    path: '/stickytokens',
    subtitle: 'a generative collection of digital stickers, living on the blockchain',
    img: test_img
}, {
    title: 'Coming soon',
    subtitle: 'I\'m always tinkering on something, so my next project might be just around the corner',
    img: stripes_img
}];

const variants = {
    reel: {
        default: {},
        scroll: {},
    },
    project: {
        default: {scale: 1, transition: {duration: .09, ease: 'linear'}},
        scroll: {scale: .9, transition: {duration: .09, ease: 'linear'}},
    }
}

// todo: adjust transform on resize
const Projects = () => {
    const [selected, setSelected] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const reelRef = useRef();

    // change selected project
    // useEffect(() => {
    //     let timeout = null;
    //     const onScroll = (e) => {
    //         if (e?.wheelDeltaY < 0) {
    //             setSelected(prev => Math.min(projects.length - 1, prev+1));
    //         } else if (e?.wheelDeltaY > 0) {
    //             setSelected(prev => Math.max(0, prev-1));
    //         }
    //         setIsScrolling(true);
    //         clearTimeout(timeout);
    //         timeout = setTimeout(() => setIsScrolling(false), 600);
    //     };
    //     window.addEventListener('wheel', onScroll);
    //     return () => {
    //         window.removeEventListener('wheel', onScroll);
    //         clearTimeout(timeout);
    //     }
    // }, []);

    // update reel transform
    // useEffect(() => {
    //     const updateReel = (e) => {
    //         const el = reelRef?.current;
    //         const x = el?.getBoundingClientRect().width * selected * -1;
    //         el.style.transform = `translateX(${x}px)`;
    //     };
    //     updateReel();
    //     window.addEventListener('resize', updateReel);
    //     return () => window.addEventListener('resize', updateReel);
    // }, [selected]);

    return <main className={'Main'}>
        <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p className={'Paragraph'}>work in progress</p>
        {/*    <div className={'Projects-Container'}>*/}
        {/*        <p className={'ProjectPreview-Index'}>{`0${selected + 1}`}</p>*/}
        {/*        <motion.div*/}
        {/*            className={'Projects-Reel'} ref={reelRef}*/}
        {/*            variants={variants.reel}*/}
        {/*            animate={isScrolling ? 'scroll' : 'default'}>*/}
        {/*            {projects.map((proj, i) => <ProjectPreview*/}
        {/*                key={i}*/}
        {/*                project={proj}*/}
        {/*                selected={selected === i}/>)}*/}
        {/*        </motion.div>*/}
        {/*        <ScrollIndicator/>*/}
        {/*    </div>*/}
        </div>
    </main>;
};

const ProjectPreview = (props) => {
    const {project, selected} = props;
    return <motion.div
        className={'ProjectPreview' + (selected ? ' ProjectPreview--selected' : '')}
        variants={variants.project}>
            <Link className={'ProjectPreview-Link'} to={'/work' + (project.path ?? '/')}>
                <div className={'ProjectPreview-Container'}>
                    <div className={'ProjectPreview-ImgContainer'}>
                        <img src={project.img} alt={'Preview'} className={'ProjectPreview-Img'}/>
                    </div>
                    <div className={'ProjectPreview-TextContainer'}>
                        <h1 className={'ProjectPreview-Title'}>{project.title}</h1>
                        <p className={'ProjectPreview-Description'}>{project.subtitle}</p>
                    </div>
                </div>
            </Link>
    </motion.div>
};

export default Projects;