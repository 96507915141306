import './styles/main.css';
import Grain from "./components/Grain";
import Header from "./components/Header";
import Projects from "./components/Projects";
import Menu from "./components/Menu";
import {useState} from "react";
import {Routes, Route, useNavigate} from "react-router-dom";
import About from "./components/About";
import ScrollIndicator from "./components/ScrollIndicator";
import Project from "./components/Project";
import {MotionConfig} from "framer-motion";
import CustomCursor from "./components/CustomCursor";
import test_img from "./assets/images/img.png";
import Experiments from "./components/Experiments";
import useResetScroll from "./helpers/useResetScroll";
import useColorScheme from "./helpers/useColorScheme";

// todo: LazyMotion & m
const App = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const {colorScheme} = useColorScheme();
    const openMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return <MotionConfig reducedMotion={"user"}>
        <div className="App">
            <Grain colorScheme={colorScheme}/>
            <div className={'Page'}>
                <Header {...{openMenu, menuOpen}} />
                <Menu {...{openMenu, menuOpen}} />
                {/*<ScrollIndicator/>*/}
                <AppRouting/>
            </div>
        </div>
        <CustomCursor/>
    </MotionConfig>;
}

const AppRouting = () => {
    return <Routes>
        <Route path={'work'} element={<Projects/>}/>
        <Route path={'work/visually'} element={<Project project={{
            title: 'Visually',
            path: '/visually',
            subtitle: 'a web application that helps users create impactful data visualizations tailored to their specific data',
            img: test_img
        }}/>}/>
        <Route path={'about'} element={<About/>}/>
        <Route path={'experiments'} element={<Experiments/>}/>
        <Route path={'/'} element={<CustomRedirect/>}/>
    </Routes>
};

const CustomRedirect = () => {
    const navigate = useNavigate();
    navigate('/about')
    return null;
};

export default App;
