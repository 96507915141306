import {motion, AnimatePresence} from "framer-motion";
import {Link, useLocation} from "react-router-dom";

const Header = (props) => {
    const location = useLocation();
    const paths = location.pathname.split('/').slice(1);

    return <header className={'Header'}>
        <div className={'Header-Group'}>
            <div/>
            <button className={'BurgerButton' + (props.menuOpen ? ' BurgerButton--close' : '')}
                    onClick={props.openMenu}>
                <div className={'BurgerButton-Inner'}/>
                <div className={'BurgerButton-Outter'}/>
            </button>
        </div>
    </header>;
};

export default Header;