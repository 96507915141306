import {Link, useLocation} from "react-router-dom";
import {motion, AnimatePresence} from "framer-motion";

const variants = {
    menu: {
        hide: {display: 'none', transition: {duration: 0, when: 'afterChildren', delay: .1}},
        show: {display: 'block', transition: {duration: 0, when: 'beforeChildren'}}
    },
    // blinds
    blinds: {
        hide: {transition: {staggerChildren: .05, staggerDirection: -1}},
        show: {transition: {staggerChildren: .05}}
    },
    blind: {
        hide: {scaleY: 0, transition: {duration: .09, ease: 'linear'}},
        show: {scaleY: 1.1, transition: {duration: .09, ease: 'linear'}}
    },
    // links
    ul: {
        hide: {},
        show: {transition: {delayChildren: .3, staggerChildren: .1}}
    },
    li: {
        hide: {},
        show: {}
    },
    word: {
        hide: {y: '-100%'},
        show: {y: 0}
    },
    index: {
        hide: {y: '-100%'},
        show: {y: 0}
    }
};

const Menu = (props) => {
    return <AnimatePresence initial={false}>
        <motion.div
            className={'Menu'}
            variants={variants.menu}
            animate={props.menuOpen ? 'show' : 'hide'}>
            <VenetianBlinds/>
            <Nav {...props}/>
        </motion.div>
    </AnimatePresence>;
};

const Nav = (props) => {
    const location = useLocation();
    return <nav className={'Nav'}>
        <motion.ul className={'Nav-List --margin-lg-lr'} variants={variants.ul}>
            <NavItem
                {...props}
                index={'01'}
                selected={location.pathname.includes('/work')}
                text={'Work'}
                path={'/work'}/>
            <NavItem
                {...props}
                index={'02'}
                selected={location.pathname.includes('/experiments')}
                text={'Experiments'}
                path={'/experiments'}/>
            <NavItem
                {...props}
                index={'03'}
                selected={location.pathname === '/about'}
                text={'About'}
                path={'/about'}/>
        </motion.ul>
    </nav>;
};

// const delay = [.04, .1, .02, 0, .07];
const NavItem = (props) => {
    return <motion.li
        className={'Nav-Item' + (props.selected ? ' Nav-Item--selected' : '')}
        variants={variants.li}>
        <Link className={'Nav-Link'} to={props.path} onClick={props.openMenu}>
            <span className={'Nav-Label Motion-Container'}>
                <motion.span className={'Nav-Index Motion-Char'} variants={variants.index}>
                    {props.index}
                </motion.span>
                <motion.span className={'Motion-Text'} variants={variants.word}>
                    {props.text}
                </motion.span>
            </span>
        </Link>
    </motion.li>;
};

const VenetianBlinds = (props) => {
    return <motion.div className={'Blinds'} variants={variants.blinds}>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
        <motion.div variants={variants.blind} className={'Blinds-Line'}/>
    </motion.div>
};

export default Menu;