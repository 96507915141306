import {motion, AnimatePresence} from "framer-motion";
import {useEffect, useRef, useState} from "react";

const easeOutQuad = [.5, 1, .89, 1];
const easeOutCubic = [.33, 1, .68, 1];

const variants = {
    heading: {
        initial: {y: '30%', opacity: 0},
        show: {y: 0, opacity: 1, transition: {delay: .6, duration: 1, ease: easeOutQuad}}
    },
    headingReverse: {
        initial: {y: '-30%', opacity: 0},
        show: {y: 0, opacity: 1, transition: {delay: .6, duration: 1, ease: easeOutQuad}}
    },
    paragraph: {
        initial: {y: '30%', opacity: 0},
        show: {y: 0, opacity: 1, transition: {delay: 1, duration: 1, ease: easeOutQuad, staggerChildren: .02, delayChildren: 1.25}}
    },
    char: {
        initial: {opacity: .2},
        show: {opacity: 1, transition: {duration: .2}}
    },
    moveTopLeft: {
        initial: {x: 100, y: '30%', opacity: 0},
        animate: {x: 0, y: 0, opacity: 1, transition: {delay: .6, duration: 1, ease: easeOutQuad}}
    },
    moveTopRight: {
        initial: {x: -100, y: '30%', opacity: 0},
        animate: {x: 0, y: 0, opacity: 1, transition: {delay: .6, duration: 1, ease: easeOutQuad}}
    }
}

export const Animate = (props) => {
    const {text, animation} = props;
    return <motion.span
        className={'Motion-Text'}
        variants={variants[animation]}
        initial={'initial'}
        animate={'animate'}>
        {text}
    </motion.span>;
}

const moveInChars = {
    container: {
        initial: {},
        animate: {}
    },
    char: {
        initial: {y: '-100%'},
        animate: {y: 0, transition: {duration: 1}}
    },
    charReverse: {
        initial: {y: '100%'},
        animate: {y: 0}
    }
}

// const delay = [.3, .1, .2, .4, .5, .12, .51, .45, .23, 0, .1];
export const AnimateHeading = (props) => {
    const {text, reverse = false} = props;
    return <motion.span
        className={'Motion-Text'}
        variants={reverse ? variants.headingReverse : variants.heading}
        initial={'initial'}
        animate={'show'}>
        {text}
    </motion.span>;
    // return <motion.span
    //     className={'Motion-Text Motion-Container'}
    //     variants={moveInChars.container}
    //     initial={'initial'}
    //     animate={'animate'}>
    //     {text.split('').map((char, idx) => (
    //         <motion.span
    //             key={idx}
    //             className={'Motion-Text'}
    //             variants={reverse ? moveInChars.charReverse : moveInChars.char}
    //             transition={{delay: delay[idx] ?? 0, duration: .6}}
    //         >
    //             {char}
    //         </motion.span>
    //     ))}
    // </motion.span>;
};

export const AnimateParagraph = (props) => {
    const {text} = props;
    return <motion.span
        className={'Motion-Text'}
        variants={variants.paragraph}
        initial={'initial'}
        animate={'show'}
    >
        {text.split('').map((letter, idx) => <motion.span
            key={idx}
            variants={variants.char}
        >
            {letter}
        </motion.span>)}
    </motion.span>
};

export const RevealOnScroll = (props) => {
    const {children, delay = false} = props;
    const ref = useRef();

    useEffect(() => {
        const el = ref?.current;
        const elementPosition = el?.offsetTop;
        let revealed = false;
        const revealElement = (e) => {
            const revealPosition = window.scrollY + window.innerHeight;
            if (!revealed && revealPosition > elementPosition) {
                console.log(revealPosition, elementPosition)
                revealed = true;
                el.classList.add('Motion-Scroll--revealed');
            }
            else if (revealed && revealPosition <= elementPosition) {
                revealed = false;
                el.classList.remove('Motion-Scroll--revealed');
            }
        };
        revealElement();
        window.addEventListener('scroll', revealElement);
        window.addEventListener('resize', revealElement);
        return () => {
            window.removeEventListener('scroll', revealElement);
            window.removeEventListener('resize', revealElement);
        }
    }, []);

    return <motion.div
        ref={ref}
        className={'Motion-Scroll' + (delay ? ' Motion-Scroll--delay' : '')}>
        {children}
    </motion.div>
};